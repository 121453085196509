*{
    margin:0px;
    padding:0px;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
    overflow-x: hidden;

}
html{
    font-size: 62.5%;
}
body{
    background-color: #F4F4F4;
}
.mainCenter{
    background-color: #028f76;
    color:white;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:1rem;
    z-index: 999;
  }
.centered video {
    width:100%;
}
.centered{
    position: relative;
}
.centered .positionSet{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 5rem;
    text-align: center;
    color:#1c2130;
    text-shadow: 0rem 0rem 0.4rem white;
    text-transform: uppercase;
}
.mainRight ul{
    display:flex;
    flex-direction: row;
    font-size: 1.6rem;
    list-style: none;
    }
.mainRight ul li{
    margin: .5rem;
    color:black;
    font-size: 1.8rem;
}
.mainRight a{
    text-decoration: none;
}
.menu-logos a.active_class{
    color:white;
    }
  .center-main{
    width:100%;
    display:flex;
    flex-direction: column;
    padding: 4rem 2rem;
    justify-content: center;
    align-items: center;
  }
  .center-right{
    width:100%;
  
  }
  .set-col{
    flex:1;
    text-align:center;
    background-color:#fff200;
    box-shadow:0rem 0rem 0.4rem black;
    margin:0rem 1rem;
    padding:2rem 4rem;
  }
  .set-row{
    flex:1;
    text-align:center;
    transition:0.6s;
    background-color:grey;
    box-shadow:0rem;
    margin:0rem 1rem;
    padding:2rem 4rem;
  }
  .center-rights{
    width:70%;
    height:40vh;
    margin:4rem 0rem;
    text-align:center;


  }
  .center-rightsz{
    width:70%;
    height:10vh;
    margin:4rem 0rem;
    text-align:center;


  }
  table,td,th {
    border:0.1rem solid black;
  }
  td:hover{
    background-color: white;
    color:black;
    box-shadow: 0rem 0rem 0.4rem black;
    
  }
  table{
    border-collapse: collapse;
    width:100%;
    font-size: 1.6rem;
    box-shadow: 0rem 0rem 0.4rem black;
    background-color: #bac9a9;  
}
  th,td{
    padding:1.5rem;
  }
  .button-design{
    padding:1rem 2rem;
    box-shadow: 0rem 0rem 0.4rem black;
    background-color: #1c2130;
    color:white;
    border:none;
  }
  .text-alignment{
    text-align:center;
  }
  .button-updated{
    padding:1rem 2rem;
    box-shadow: 0rem 0rem 0rem black;
    background-color: #1c2130;
    color:white;
    border:none;
  }
  footer{
    background-color: #1c2130;
    color:white;
    text-align: center;
    padding:1.5rem 0rem;
    box-shadow: 0rem 0rem 0.4rem black;
    font-size: 1.4rem;
  }

.desktop-view{
  width:100%;
  background-color: #028f76;
  display:flex;
  align-items: center;
  padding:0rem 5rem; 
  height:5rem;
  box-shadow: 0rem 0rem 0.4rem black;
  z-index: 999;
}
.centralize{
  display:flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;

}
.one1 img{
  box-shadow: 0rem  0rem 0.4rem 0rem black;
}
.one1 h1{
  margin-top: 1rem;
  text-shadow: 0rem 0rem 0.4rem black;
}
.size1{
  width:80%;
  height:80%;
  /* position: absolute; */
  width: 100%;
  height: auto;
}
.one1{
  display:flex;
  flex-direction: column;
  align-items: center;
  color:white;
  justify-content: center;
  padding:2rem;
  background-color: #028f76;
  box-shadow:0rem 0rem 0.4rem rgb(15, 82, 76);
  margin:1rem;
  border-radius: 0.2rem;
  position:relative;
  width: 100%;
  /* height: 20rem; */
  }
.logos-left{
  width:30%;
  display: flex;
  color:white;
  justify-content: start;
  text-transform: uppercase;
}
.menu-logos{
  width:100%;
  display:flex;
  justify-content: end;
}
.menu-logos ul li{
  display:inline;
  font-size: 1.8rem;
  margin:0rem 2rem;
  list-style: none;
}
.setLog{
  position: absolute;
  z-index: 1000;
  width:10%;
  top:5rem;
}
.menu-logos ul li a{
  text-decoration: none;
}
.home-btn{
  text-decoration: none;
  color:white;
}
.contact-set{
  width:100%;
display:flex;
background-color: #028f76;
justify-content: flex-start;
padding:2rem;
}

.menu-logos ul li a:hover{
  letter-spacing: 0.5rem;
  transition: 0.6s;
  color:white;
}
.mains-hamburger{
  width:100%;
  background-color: #028f76;
  display:flex;
  align-items: center;  
  padding:0rem 5rem; 
  height:5rem;
  box-shadow: 0rem 0rem 0.4rem black;
  z-index: 999;

}
.hamburger-left{
  width:100%;
  display: flex;
  justify-content: start;
}
.hamburger-right{
  width:100%;
  display:flex;
  justify-content: end;
}
.menu-collapse{
  position: absolute;
  z-index: 999;
  width: 100%;
 }
.menu-collapse ul{
  background-color: white;
  box-shadow: 0rem 5rem 15rem black;
margin:0rem 0.5rem 0rem 0.5rem;

}
  .menu-collapse ul li{
  transition: 0.5s;
  padding:2rem 2rem;
  list-style: none;
  font-size: 1.6rem;
  }
  .menu-collapse ul li:hover{
    background-color: grey;
    
  }
  .menu-collapse ul li a{
    text-decoration: none;
    color:black;
  }
  .mission-section{
    width:100%;
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
    align-items: center;
    justify-content: center;
    padding:6rem 0rem;
    background-color: rgb(202, 192, 192);
  }
  .mission-content{
    width:100%;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    box-sizing: border-box;
  }
  .mission-cont1{
flex:1;
width:100%;
  }
  .pdf-button{
   padding:0.5rem 3rem;
   background-color:#028f76;
   border:none;
   margin:0rem 1rem;
   color:white;
  }
  .mission-cont2{
    flex:1;
    width:100%;
    margin:4rem;
   

  }
  canvas{
    width:100% !important;
    height:auto !important;
  }
  .modal-design
  {
    width:100vw;
    height:100vh;
   display: flex;
  align-items: center;
  justify-content: center;
  /* background-color:rgba(245, 221, 9, 0.767);
  color:black;
  font-size: 1.6rem; */
  position:fixed;
  /* padding:5rem; */
  transition: 0.6s;
  z-index:999;
  /* transform:translate(-50% -50%); */
  }
  .modal-design img{
  width:30%;
  height:auto;
  box-shadow: 0rem 0rem 0.4rem black;
  }
  .style-para{
    font-size:1.6rem;
    text-transform:capitalize;
    margin:1rem 0rem;
    width:50%;
    text-align:center;
  }
  .fees-view{
    width:100rem;
    height:50rem;
  }
  .fees-design{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    box-shadow: 0px 0px 10px black;
  }
 
  @media (min-width:778px){
  .mobile-view{
    display:none;
  }
  
}
@media (max-width:778px){
  .centralize{
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
  
  }
  .desktop-view{
    display:none;
  }
  .fees-view{
    width:40rem;
    height:20rem;
  }
  .fees-design{
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    box-shadow: 0px 0px 10px black;
  }
  .style-para{
    font-size:1.6rem;
    text-transform:capitalize;
    margin:1rem 0rem;
    width:80%;
    text-align:center;
  }
  .mission-section{
    width:100%;
    display: flex;
    flex-direction: column;
    flex-wrap:wrap;
    justify-content: center;
    padding:1rem 0rem;
    background-color: rgb(202, 192, 192);
  }
  .modal-design
  {
    width:100vw;
    height:100vh;
   display: flex;
  align-items: center;
  justify-content: center;
  position:fixed;
  transition: 0.6s;
  z-index:999;
  }
  .modal-design img{
    width:90%;
    height:auto;
    box-shadow: 0rem 0rem 0.4rem black;
    }
  canvas{
    width:100% !important;
    height:auto !important;
  }
  table{
    border-collapse: collapse;
    width:100%;
    font-size: 1.4rem;
    box-shadow: 0rem 0rem 0.4rem black;
    background-color: #bac9a9;  
    box-sizing: border-box;
}
th,td{
  padding:0.5rem;
  box-sizing: border-box;
}
  .center-right{
    width:100%;
    margin-top: 1rem;
    text-align: center;
  }
  .center-rightsz{
    width:70%;
    height:20vh;
    margin:4rem 0rem;
    text-align:center;
 }
  .mission-cont2{
    flex:1;
    width:80%;
    font-size: 1.4rem;
    margin:4rem;
   }
  html{
    font-size: 50%;
  }
  .centered{
    position: relative;
}
.centered .positionSet{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 3rem;
    text-align:center;
    color:#1c2130;
    text-shadow: 0rem 0rem 0.4rem white;
    text-transform: uppercase;
}
.mission-content{
  width:100%;
  display: flex;
  flex-wrap:wrap;
  flex-direction: column;
}
}
